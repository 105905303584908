<template>
  <div class="w-full">
    <div class="flex flex-col sm:flex-row gap-4 items-center mb-12">
      <Title title="Wallet" size="large" />

      <Button
        id="button-streamer-request-withdraw"
        class="ml-auto"
        kind="primary"
        label="Request withdraw"
        :grow="windowWidth < 640 ? true : false"
        @clicked="requestWithdraw"
      />
    </div>

    <div class="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-8 mb-16 w-full">
      <KPI label="Available for withdraw" :value="`€${wallet.withdrawable.toFixed(1)}`" />

      <KPI label="Locked pending campaign completion" :value="`€${wallet.locked.toFixed(1)}`" />

      <KPI
        v-if="wallet.totalWithdrawed > 0"
        label="Total withdrawed"
        :value="`€${wallet.totalWithdrawed.toFixed(1)}`"
      />

      <KPI v-else label="Earned today" :value="`€${wallet.today.toFixed(1)}`" />

      <KPI label="Earned this week" :value="`€${wallet.week.toFixed(1)}`" />

      <KPI label="Earned this month" :value="`€${wallet.month.toFixed(1)}`" />

      <KPI label="Earned this year" :value="`€${wallet.year.toFixed(1)}`" />

      <KPI label="Total earned" :value="`€${wallet.totalEarned.toFixed(1)}`" />
    </div>

    <div class="flex items-center mb-4 w-full">
      <Title title="Withdraw history" size="medium" />
    </div>

    <div>
      <div v-if="withdrawHistory" class="flex flex-col gap-3 w-full lg:w-1/2">
        <div class="grid grid-cols-2">
          <Button kind="link" class="m-auto" label="Amount" />
          <Button kind="link" class="m-auto" label="Date" />
        </div>

        <div
          v-for="(withdrawal, withdrawalIndex) of withdrawHistory"
          :key="withdrawalIndex"
          class="grid grid-cols-2 py-4 text-center bg-blue-default rounded"
        >
          <div>
            {{ `€${withdrawal.amount}` }}
          </div>

          <div>
            {{ new Date(withdrawal.createdDate).toDateString() }}
          </div>
        </div>
      </div>

      <p v-else>No withdraws yet</p>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { KPI, Title, Button } from "cavea-design-system";

export default {
  name: "StreamerWallet",

  metaInfo: {
    title: "Wallet",
  },

  components: {
    Title,
    Button,
    KPI,
  },

  data() {
    return {
      windowWidth: 1920,
      withdrawHistory: null,
      wallet: {
        locked: 0,
        withdrawable: 0,
        totalEarned: 0,
        totalWithdrawed: 0,
        year: 0,
        month: 0,
        week: 0,
        today: 0,
      },
    };
  },

  computed: {
    ...mapGetters(["getUserInfo"]),
  },

  watch: {
    getUserInfo: {
      handler() {
        if (this.getUserInfo?._id) {
          this.getWallet();
          this.getWithdrawHistory();
        }
      },
    },
  },

  created() {
    this.windowWidth = window?.innerWidth || 1920;

    window.addEventListener("resize", () => {
      this.windowWidth = window?.innerWidth || 1920;
    });

    this.getWallet();
    this.getWithdrawHistory();
  },

  methods: {
    async getWallet() {
      const HOST = process.env.VUE_APP_API_URL;
      const URL = `${HOST}/content-creator/wallet/${this.getUserInfo._id}`;

      const walletData = await axios
        .get(URL)
        .then((res) => res?.data?.wallet)
        .catch((err) => {
          console.error("getWallet error", err);

          return {
            locked: 0,
            withdrawable: 0,
            totalEarned: 0,
            totalWithdrawed: 0,
            year: 0,
            month: 0,
            week: 0,
            today: 0,
          };
        });

      if (walletData) {
        this.wallet = walletData;
      }
    },

    requestWithdraw() {
      this.$swal({
        icon: "info",
        title: "Request withdraw",
        text: "We are working on implementing an automatic withdraw system. In the meantime, please send an email to jg@adlab.gg with the title 'Withdraw request' if you wish to withdraw.",
      });
    },

    async getWithdrawHistory() {
      if (this?.getUserInfo?._id) {
        const HOST = process.env.VUE_APP_API_URL;
        const URL = `${HOST}/withdraws/streamer/${this.getUserInfo._id}`;

        this.withdrawHistory = await axios
          .get(URL)
          .then((res) => res?.data?.withdrawHistory || [])
          .catch((error) => {
            console.error("Error getting withdraw history", error);
            return [];
          });
      }
    },
  },
};
</script>
